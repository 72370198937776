import React, { useState, useEffect } from 'react';
import styles from "./Contato.module.css";
import RiWhatsappLine from 'remixicon-react/WhatsappLineIcon';
import RiPhoneLine from 'remixicon-react/PhoneLineIcon';
import RiMailSendLine from 'remixicon-react/MailSendLineIcon';
import emailjs from '@emailjs/browser';
import Footer from '../../components/footer/Footer';
import Whatsapp from '../../components/whatsapp/Whatsapp';
import { ThreeDots } from 'react-loader-spinner';
import Swal from 'sweetalert2'

function Contato() {
  const whatsappMessage = "https://api.whatsapp.com/send?phone=5511943600303&text=Estou%20na%20p%C3%A1gina%20de%20contato,%20gostaria%20de%20falar%20com%20um%20especialista";

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [message, setMessage] = useState('');

    // Loader
    const [isLoading, setIsLoading] = useState(false);
  
    const isFormValid = () => {
      return name.trim() !== '' && email.trim() !== '' && whatsapp.trim() !== '' && message.trim() !== '';
    };

    function sendEmail(e) {
      e.preventDefault();

      setIsLoading(true);

      const templateParams = {
        from_name: name,
        email: email,
        whatsapp: whatsapp,
        message: message,
      };

      emailjs.send("service_b51o8m4", "template_33aut19", templateParams, "hXcGtmPBfpBa4B1jl")
      .then((response) => {
        console.log('Email enviado', response.status, response.text);
        setIsLoading(false);

        setName('')
        setEmail('')
        setWhatsapp('')
        setMessage('')

        Swal.fire({
          title: 'Recebemos sua mensagem!',
          text: 'Em breve um de nossos especialistas entrará em contato com você! A Goodds agradece o seu contato :)',
          icon: 'success',
          confirmButtonColor: '#298C7C',
          confirmButtonText: 'Fechar'
        });


      }, (err) => {
        console.log('Email não enviado', err)
        setIsLoading(false);

        Swal.fire({
          title: 'Ops... parece que recebemos um erro ao tentar enviar sua mensagem.',
          text: 'Mas não se preocupe, entre em contato pelo nosso WhatsApp, estamos sempre disponíveis por lá :)',
          icon: 'error',
          confirmButtonColor: '#fe3b3b',
          confirmButtonText: 'Fechar'
        });
      })
    }


    // Função de manipulação do clique
      const handleClick = (e) => {

        // Chamar a função de rastreamento de conversão
        if (window.gtag_report_conversion) {
        window.gtag_report_conversion(whatsappMessage);
        } else {
        console.warn("gtag_report_conversion não está definido.");
        }

        // Redirecionar após um curto delay para garantir que o evento de conversão foi enviado
        setTimeout(() => {
        window.open(whatsappMessage, '_blank');
        }, 500); // Ajuste este delay conforme necessário
    };

    return (

        <div>
            <div className={styles.contactPage}>
                <h1>Entre em contato conosco</h1>

                <form className={styles.contactModalForm} onSubmit={sendEmail}>
                    <div className={styles.contactModalInfo}>
                        <input
                        className={`${styles.contactModalInput} titleColor`}
                        placeholder="Nome completo ou empresa"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        />
                    </div>
                    <div className={styles.contactModalInfo}>
                        <input
                        className={`${styles.contactModalInput} titleColor`}
                        placeholder="Seu melhor email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        />
                    </div>
                    <div className={styles.contactModalInfo}>
                        <input
                        className={`${styles.contactModalInput} titleColor`}
                        placeholder="WhatsApp ou seu número com código de área ou DDD"
                        type="number"
                        value={whatsapp}
                        onChange={(e) => setWhatsapp(e.target.value)}
                        required
                        />
                    </div>
                    <div className={styles.contactModalInfo}>
                        <textarea
                        className={`${styles.contactModalTextarea} titleColor`}
                        placeholder="Escreva sua mensagem"
                        value={message}
                        rows={6}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        />
                    </div>

                    {isLoading ? (
                      <div className={styles.loaderContainer}>
                        <ThreeDots color="#298C7C" height={50} width={50} />
                      </div>
                    ) : (
                      <button className={`${styles.contactModalSubmit} ${!isFormValid() ? `${styles.buttonDisabled}` : '' }`} disabled={!isFormValid()}>Enviar</button>
                    )}

                </form>

                <div className={styles.gridContacts}>
                    <p>
                        <span><RiPhoneLine /></span>
                        (11) 5225-8173
                    </p>
                    
                    <a href={whatsappMessage} rel="noreferrer" target="_blank">
                        <p>
                            <span><RiWhatsappLine /></span>
                            (11) 94360-0303
                        </p>
                    </a>
                        
                    <a href="mailto:contato@goodds.com.br">
                        <p>
                            <span><RiMailSendLine /></span>
                            contato@goodds.com.br
                        </p>
                    </a>
                </div>
            </div>

            <Whatsapp whatsappMessage={whatsappMessage} onClick={handleClick} />
            <Footer />
        </div>
    )
}

export default Contato;