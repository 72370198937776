import React from "react";
import styles from './PackagesGlobal.module.css';
import PackagesSection from "../packages-section/PackagesSection";


function PackagesGlobal({ showTitle, titleContent }) {
    // DROP LATAM
    const dropLatam1 = {
        packageTitle1: "Loja Dropshipping Global ou Latam Super",
        packageDescription1: "Desenvolvimento da Loja Virtual com logo profissional para venda Internacional, mineração e cadastro de 15 produtos já integrados e traduzidos com fornecedores.",
        packagePrice1: "5.997",
        packageNews1: "2.997",
        packageParcel1: "303,72",
        packageName1: " no Super",
        included1Package1: "Criação da Loja Virtual Completa",
        included2Package1: "Criação da Logomarca",
        included3Package1: "Checkout para Venda Internacional",
        included4Package1: "Cadastro de 15 Produtos em Inglês ou Espanhol",
        included5Package1: "Mentoria + Consultoria",
        included6Package1: "Curso com Centenas de Aulas",
        included7Package1: "Suporte Especializado 24h",
        included8Package1: "Garantia Vitalícia",
        included9Package1: "Domínio Grátis (www.suamarca.com.br)",
        included13Package1: "Anúncios (Facebook e Instagram)",
        included14Package1: "30 Dias de Assessoria de Marketing",
        packageLink1: "https://dropshipping-goodds.catalog.yampi.io/pacote-loja-de-dropshipping-global-super/p",
        bandeiras1: true,
        bandeiraEua1: true,
        bandeiraEs1: true,
        btnName1: "Comprar"
    };
    const dropLatam2 = {
        packageTitle2: "Loja Dropshipping Global ou Latam Mega",
        packageDescription2: "Desenvolvimento da Loja Virtual e da Logo para venda Internacional com mineração e cadastro exclusivo de 30 produtos já integrados e traduzidos com fornecedores + 1 anúncio.",
        packagePrice2: "7.997",
        packageNews2: "3.997",
        packageParcel2: "405,06",
        packageName2: " no Mega",
        included1Package2: "Criação da Loja Virtual Completa",
        included2Package2: "Criação da Logomarca",
        included3Package2: "Checkout para Venda Internacional",
        included4Package2: "Cadastro de 30 Produtos em Inglês ou Espanhol",
        included5Package2: "Mentoria + Consultoria",
        included6Package2: "Curso com Centenas de Aulas",
        included7Package2: "Suporte Especializado 24h",
        included8Package2: "Garantia Vitalícia",
        included9Package2: "Domínio Grátis (www.suamarca.com.br)",
        included10Package2: "1 Anúncio em Vídeo (Facebook e Instagram)",
        included13Package2: "30 Dias de Assessoria de Marketing",
        packageLink2: "https://dropshipping-goodds.catalog.yampi.io/pacote-loja-de-dropshipping-global-mega/p",
        bandeiras2: true,
        bandeiraEua2: true,
        bandeiraEs2: true,
        btnName2: "Comprar"
    };
    const dropLatam3 = {
        packageTitle3: "Loja Dropshipping Global ou Latam Especial",
        packageDescription3: "A solução mais completa e mais vendida para venda Internacional, inclui tudo do pacote Mega + 30 produtos extras + assessoria de marketing dedidacada e 2 anúncios.",
        packagePrice3: "9.997",
        packageNews3: "4.997",
        packageParcel3: "506,40",
        packageName3: " no Especial",
        included1Package3: "Criação da Loja Virtual Completa",
        included2Package3: "Criação da Logomarca",
        included3Package3: "Checkout para Venda Internacional",
        included4Package3: "Cadastro de 60 Produtos em Inglês ou Espanhol",
        included5Package3: "Mentoria + Consultoria",
        included6Package3: "Curso com Centenas de Aulas",
        included7Package3: "Suporte Especializado 24h",
        included8Package3: "Garantia Vitalícia",
        included9Package3: "Domínio Grátis (www.suamarca.com.br)",
        included10Package3: "2 Anúncios em Vídeo (Facebook e Instagram)",
        included11Package3: "30 Dias de Assessoria de Marketing",
        packageLink3: "https://dropshipping-goodds.catalog.yampi.io/pacote-loja-de-dropshipping-global-especial/p",
        bandeiras3: true,
        bandeiraEua3: true,
        bandeiraEs3: true,
        btnName3: "Comprar"
    };

    return (
        <div id="packagesDrop">
            <div className="titlePackagesDrop">
                {showTitle && <h1 id="pacotesDoDropTitle">{titleContent}</h1>}
            </div>
            <div>
                <PackagesSection packageData1={dropLatam1} segundoPacote={true} packageData2={dropLatam2} terceiroPacote={true} packageData3={dropLatam3} sectionPackageTitle="" />
            </div>
        </div>
    )
}

export default PackagesGlobal;