import React from 'react';
import { Helmet } from 'react-helmet';
import './ReclameAquiIcon.css'

function ReclameAquiIcon() {
    return (
        <div id="ra-verified-seal">
            <Helmet>
                <script type="text/javascript" id="ra-embed-verified-seal" src="https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js" data-id="UFRHcXZ6YWktZXhBRGlaTjpnb29kZHM=" data-target="ra-verified-seal" data-model="1"></script>
            </Helmet>
        </div>
    );
}

export default ReclameAquiIcon;